import React from 'react';
import tw, { styled, css } from 'twin.macro';
import Logo from '../assets/ccc-logo.svg';
import Data from '../data/index.yml';

const Main = styled.main`
  background-color: #82a943;
  ${tw`text-white font-body absolute inset-0 w-full h-full px-6 pt-6 pb-12 overflow-y-auto text-center md:(px-12 pt-12 pb-24) lg:(text-left)`}
`;
const BlackoutTitle = styled.h3`
  ${tw`px-4 py-2 text-xl font-display uppercase bg-black text-white inline-block rounded-md md:text-4xl`}
`;
const paragraph = css`
  b {
    font-size: 1.2em;
  }
`;
const bigNumber = css`
  ${tw`font-display`}
  font-size: 100px;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 180px;
  }
  @media (min-width: 1280px) {
    font-size: 220px;
  }
`;
const std = css`
  ${tw`
    font-display text-2xl font-bold uppercase mt-8
    xl:(text-3xl)
  `}
  b {
    ${tw`text-black`}
  }
`;
const IndexPage = () => (
  <Main>
    <div tw="relative">
      <Logo css={tw`w-48 block mx-auto lg:(w-72)`} />
      <div
        css={tw`
        lg:(flex py-12 max-w-screen-xl mx-auto items-center)
        xl:(pt-20 pb-16)
        `}
      >
        <div
          css={tw`
            mt-6
            lg:(mt-0 pr-6 flex-grow-0)
            xl:(pr-12)
          `}
        >
          <p css={bigNumber}>{Data.bigNum}</p>
          <p
            tw="font-body text-white mt-4 text-lg md:text-xl xl:(text-2xl mt-0)"
            dangerouslySetInnerHTML={{ __html: Data.closedMsg }}
          ></p>
        </div>
        <div
          css={tw`
          mt-6 pt-6 border-t border-white
          md:(pt-12 mt-12)
          lg:(border-t-0 border-l pt-0 mt-0 pl-6 flex-grow)
          xl:(pl-12)
          `}
        >
          <BlackoutTitle>{Data.title}</BlackoutTitle>
          <ul tw="md:mt-6 md:text-lg">
            {Data.content.map(({ item }, index) => (
              <li key={item} tw="flex mt-4">
                <span
                  css={tw`mr-1 font-display font-bold text-2xl lg:(mr-2 -mt-1)`}
                >
                  {index + 1}:
                </span>
                <div
                  css={paragraph}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        css={tw`
        text-center
      `}
      >
        <p css={std} dangerouslySetInnerHTML={{ __html: Data.saveTheDate }}></p>
        <p tw="mt-4" dangerouslySetInnerHTML={{ __html: Data.contacts }}></p>
      </div>
    </div>
  </Main>
);

export default IndexPage;
